<template>
  <div id="gstc-id" style="height: 100%">
    <div class="toolbox" id="toolbox">
      <editor-select
        class="date-select-gstc"
        :value="source.zoom.level"
        @change="$set(source.zoom, 'level', $event)"
        :options="{
              multiple: false,
              clearable: true,
              options: source.options
            }"
      ></editor-select>
      <el-button class="button-zoom" icon="el-icon-plus" @click="changeZoomLevel(null, 'plus')"></el-button>
      <el-button class="button-zoom" icon="el-icon-minus" @click="changeZoomLevel(null, 'minus')"></el-button>
      <el-button
        class="pdf-download"
        @click="exportToPDF"
        icon="el-icon-download"
      >PDF</el-button>
      <editor-select
        class="items-movement-select-gstc"
        :value="source.chart_items_movement"
        @change="$set(source, 'chart_items_movement', $event)"
        :options="{
              multiple: false,
              clearable: true,
              options: source.chart_items_movement_options
            }"
      ></editor-select>
      <el-button class="button-zoom" @click="onFullScreenChange($event)">
        <font-awesome-icon icon="arrows-alt"></font-awesome-icon>
      </el-button>
    </div>
    <div class="gstc" ref="gstc"></div>
  </div>
</template>

<script>

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import GSTC from 'gantt-schedule-timeline-calendar'
import { Plugin as TimelinePointer } from 'gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js'
import { Plugin as Selection } from 'gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js'
import { Plugin as ItemResizing } from 'gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js'
import { Plugin as ItemMovement } from 'gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js'
import { Plugin as ExportImage } from 'gantt-schedule-timeline-calendar/dist/plugins/export-image.esm.min.js'
import { Plugin as ExportPDF } from 'gantt-schedule-timeline-calendar/dist/plugins/export-pdf.esm.min.js'
import { Plugin as ItemTypes } from 'gantt-schedule-timeline-calendar/dist/plugins/item-types.esm.min.js'
import { Plugin as DependencyLines } from 'gantt-schedule-timeline-calendar/dist/plugins/dependency-lines.esm.min.js'
import 'gantt-schedule-timeline-calendar/dist/style.css'

import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import clickTableMixin from '@/components/Registry/Columns/click_table_mixin'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'

import ActionExecutor from '@/core/infrastructure/service/ActionExecutor.ts'
import FilterBuilder, { EComponentTypes } from '../utils'
import { PopupManager } from 'element-ui/src/utils/popup'
library.add(faArrowsAlt)

let gstc, state
const GANTT_HEADER_HEIGHT = 75
const LICENSE_KEY = '====BEGIN LICENSE KEY====\\nCw9JRn9VmgTpAwMUmFdqyutkLBrEr+G4YP5DYKKXUU38hy2BWRJQ0ca0Ksz/MjftQ/wjM7JNz65JicZvcpjv+4zZARTkon7XlghChHII87v4x2vuc8+PenujQg2k2/pLRlN33VVTl/O0L/J76G/IKdGla1hyBqoM4bfjhfLjTqkD5utz0zXCKhaI0k9M7YR26HdKg3fuAFbYLtLXW4ArSa/xYgK4m7xOyc61/ppcMYSdXN+W/JtfQfTEx+oVlsTKMiHzaQgs7Mvt2T5z37m0TavP6QoqK3qy3rz1NvsLZJtMHbMaPhNioub8M3VAJV5PkW5AXveXk6KbPCJ9kaOF0A==||U2FsdGVkX1+ETUNx0RJagZ0BGW2/h6vmqaBr0h6EkfGU1+O2SfPdk3Eac5Y4B23xb0H1PnPCTDGLk7ZPhpst8qY7wxJY9+9yd9JoSVwPhJg=\\nhWJwPygjCHTgcD+eKRjL/83AXxjw67KwuCoipLA8RBAgTprC6F3eD5BMbc2CB4aZEoc1KsHlk0bMh+fyiLzd1OuNU4zOlH+cxFafXbLQXibmbI/Mvf5WcirHJWNMW9P2rgFDPoLH+SI2bSNUyudzjT8NNaN2ZCG8kMCFaX14i9VkToj9b9Tr7jW2RNFoz7mvAmBbJmj50mxaPe8Vc+uEsmMJnOqF36zaqtp8yYSdvz6WEqneDjQbOPYHuUsyQ0/kXmFnbXCY56ggV8rfxbe6CfX6Hhz/BpQSVEr3DxQ+6JX2KQ0nOH3Ulk1qI1urb84nkTUDFnWxnRrbH15ZEnvDsg==\\n====END LICENSE KEY===='

export default {
  name: 'a-gantt-new',
  mixins: [mixin, VisibleMixin, clickTableMixin, openCardMixin],
  components: {
    EditorSelect,
    FontAwesomeIcon
  },
  inject: {
    getViewer: {
      default: () => {
        return {}
      }
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceEditorVersion: {
      default: () => () => 1
    },
    getInterfaceWrapper: {
      default: () => () => {}
    }
  },
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    source: {
      type: Object,
      editor: 'GanttSource',
      default: () => {
        return {
          is_separated_source: false,
          is_display_relations: false,
          is_save_date_on_move_item: false,
          use_group_by_attr: false,
          group_by_attr: 'none',
          table_source: {
            type: null,
            entityId: null,
            link_attribute: null,
            filters: {},
            attrs: {},
            key_field: null
          },
          timeline_source: {
            type: null,
            entityId: null,
            link_attribute: null,
            filters: {},
            attrs: {},
            key_field: null
          },
          row_keys: {
            label: null,
            start_date: null,
            end_date: null,
            tooltip: null,
            style: null
          },
          chart_item_action: {
            plugin_name: null,
            type: 'execute_plugin',
            dashboard: {
              id: null,
              isFullscreen: false,
              window_width: 25,
              window_title: null,
              breadcrumbByButton: true
            },
            command: {
              id: null,
              success_text: null,
              failure_text: null
            },
            card: {
              registryId: null,
              type: null,
              fieldId: null,
              constantRecordId: null,
              cardId: null,
              frameGuid: null,
              containerAlias: null,
              isWindow: false,
              windowWidth: 25,
              windowTitle: null,
              defaults: []
            },
            reports: {
              id: null,
              guid: null,
              name: null,
              formatType: null,
              viewType: null,
              registryId: null,
              assocFieldId: null,
              openSavedVersion: false
            },
            url: {
              url: null,
              openType: null
            }
          },
          chart_item_background_color: null,
          chart_item_overlap: false,
          chart_item_progress: null,
          chart_item_type: 'project',
          chart_item_relation_line_type: 'straight',
          chart_item_parent_task: null,
          zoom: {
            level: 24
          },
          options: [
            { id: 20, name: 'Дни' },
            { id: 24, name: 'Месяцы (по недельно)' },
            { id: 21, name: 'Месяцы (с днями)' },
            { id: 27, name: 'Годы (с месяцами)' },
            { id: 26, name: 'Месяцы (без недель/дней)' }
          ],
          chart_items_movement_options: [
            { id: 'forbid', name: 'Запретить перетаскивание' },
            { id: 'single', name: 'Разрешить одиночное перетаскивание' },
            { id: 'multiple', name: 'Перетаскивать с учетом связей' }
          ],
          chart_items_movement: 'forbid',
          default_container: null,
          current_container: null,
          containers: [],
          is_full_screen: false
        }
      }
    }
  },
  watch: {
    'source.zoom.level': {
      handler (value) {
        this.changeZoomLevel(value, null)
      }
    },
    source: {
      handler (value) {
        let columns = this.getColumns()
        if (columns) {
          state.update('config.list.columns.data', columns)
        }
      }
    },
    'source.chart_items_movement': {
      handler (value) {
        if (value !== 'forbid') {
          state.update('config.plugin.ItemMovement.enabled', true)
        }
      }
    }
  },
  async beforeMount () {
    if (this.getInterfaceEditorVersion() === 2) {
      this.$set(this.source, 'default_container', JSON.parse(JSON.stringify(this.getContainersStore().getByGuid(this.$attrs.guid))))
      this.$set(this.source, 'current_container', this.getContainersStore().getByGuid(this.$attrs.guid))

      this.$set(this.source, 'containers', this.getContainersStore().refs.map(item => {
        return {
          guid: item.guid,
          zIndex: item.element.$el.style.zIndex
        }
      }))
    }
  },
  async mounted () {
    let summaryHeaderHeight = document.getElementById('toolbox').offsetHeight + GANTT_HEADER_HEIGHT
    let ganttSummaryHeight = document.getElementById('gstc-id').offsetHeight > 0 ? document.getElementById('gstc-id').offsetHeight - summaryHeaderHeight : 350
    let config = {
      innerHeight: ganttSummaryHeight,
      headerHeight: GANTT_HEADER_HEIGHT,
      licenseKey: LICENSE_KEY,
      plugins: [
        TimelinePointer(),
        Selection(),
        ItemResizing(),
        ItemMovement(this.itemMovementOptions()),
        ExportImage(),
        ExportPDF(),
        ItemTypes(),
        DependencyLines({
          onLine: [
            (line) => {
              line.type = this.source.chart_item_relation_line_type
              return line
            }
          ]
        })
      ],
      list: {
        expander: {
          icons: {
            child: ''
          }
        },
        columns: {
          data: this.getColumns()
        }
      },
      rows: {},
      chart: {
        items: {},
        time: {
          zoom: this.source.zoom.level
        }
      },
      locale: this.getLocalization(),
      actions: {
        'list-column-row': [this.columnClickAction],
        'chart-timeline-items-row-item': [this.chartItemClickAction]
      }
    }

    let rowData
    let rows
    let chartItems
    let entityId = this.getEntityId(this.source.table_source.type, this.source.table_source)

    if (entityId) {
      rowData = await this.getRowData(entityId, this.source.table_source.type, this.source.table_source.filters)

      rows = this.createRows(
        rowData,
        this.source.row_keys.label,
        this.source.group_by_attr,
        this.source.row_keys.start_date,
        this.source.row_keys.end_date,
        this.source.table_source.link_attribute,
        this.source.table_source.key_field
      )

      if (this.source.is_separated_source) {
        entityId = this.getEntityId(this.source.timeline_source.type, this.source.timeline_source)
        rowData = await this.getRowData(entityId, this.source.timeline_source.type, this.source.timeline_source.filters)
        let timelineRows = this.createRows(
          rowData,
          this.source.row_keys.label,
          this.source.group_by_attr,
          this.source.row_keys.start_date,
          this.source.row_keys.end_date,
          this.source.timeline_source.link_attribute,
          this.source.timeline_source.key_field
        )
        chartItems = this.createChartItems(
          timelineRows,
          this.source.row_keys.start_date,
          this.source.row_keys.end_date,
          this.source.timeline_source.link_attribute,
          this.source.chart_item_progress,
          this.source.chart_item_parent_task
        )
      } else {
        chartItems = this.createChartItems(
          rows,
          this.source.row_keys.start_date,
          this.source.row_keys.end_date,
          null,
          this.source.chart_item_progress,
          this.source.chart_item_parent_task
        )
      }

      config.list.rows = rows
      config.chart.items = chartItems
    }

    state = GSTC.api.stateFromConfig(config)
    gstc = GSTC({
      element: this.$refs.gstc,
      state
    })
  },
  beforeUnmount () {
    if (gstc) gstc.destroy()
  },
  methods: {
    changeZoomLevel (zoomLevel, action) {
      if (action === 'plus') {
        this.$set(this.source.zoom, 'level', this.source.zoom.level + 1)
        zoomLevel = this.source.zoom.level
      } else if (action === 'minus') {
        this.$set(this.source.zoom, 'level', this.source.zoom.level - 1)
        zoomLevel = this.source.zoom.level
      }
      state.update('config.chart.time.zoom', zoomLevel)
    },
    getLocalization () {
      return {
        name: 'ru',
        weekdays: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split(
          '_'
        ),
        weekdaysShort: 'Вск_Пнд_Втр_Сред_Чет_Птн_Сбт'.split('_'),
        weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
        months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
          '_'
        ),
        monthsShort: 'Янв_Фев_Мрт_Апр_Май_Июнь_Июль_Авг_Снт_Окт_Нбр_Дек'.split('_'),
        weekStart: 1,
        relativeTime: {
          future: 'в %s',
          past: '%s назад',
          s: 'несколько секунд',
          m: 'минута',
          mm: '%d минут',
          h: 'час',
          hh: '%d часов',
          d: 'день',
          dd: '%d дней',
          M: 'месяц',
          MM: '%d месяцев',
          y: 'год',
          yy: '%d лет'
        },
        formats: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd, D MMMM YYYY HH:mm'
        },
        ordinal: (n) => {
          const s = ['th', 'st', 'nd', 'rd']
          const v = n % 100
          return `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`
        }
      }
    },
    getColumns () {
      let GSTCID = GSTC.api.GSTCID
      let result = {}

      if (this.source.table_source.columns) {
        this.source.table_source.columns.forEach((item) => {
          if (item.value) {
            result[GSTCID(item.id)] = {
              id: GSTC.api.GSTCID(item.id),
              width: item.ganttColumnWidth ? item.ganttColumnWidth : null,
              expander: ({ row }) => !!row.parentId,
              data: ({ row }) => {
                let parsedAttr = this.parseAttr(row.custom_properties[item.value])

                if (typeof parsedAttr === 'string') {
                  return GSTC.api.sourceID(parsedAttr)
                } else if (typeof parsedAttr === 'object') {
                  return GSTC.api.sourceID(parsedAttr.name)
                } else if (parsedAttr === 'пусто') {
                  return GSTC.api.sourceID('')
                } else {
                  return GSTC.api.sourceID(String(parsedAttr))
                }
              },
              header: {
                content: item.text
              },
              action: item.action
            }
          }
        })
      }

      return result
    },
    columnClickAction (element, data) {
      let me = this
      function onClick (event) {
        if (event.target.classList.contains('gstc__list-column-row-expander-toggle-open') ||
          event.target.classList.contains('gstc__list-column-row-expander-toggle-closed')
        ) {
          return
        }
        let action = data.column.action
        me.getModel()[action.card.fieldId] = data.row.custom_properties[action.card.fieldId]
        ActionExecutor.execute(me, { readonly: me.readonly, pluginName: action.plugin_name, action: action, event: event })
      }

      element.addEventListener('click', onClick)

      return {
        update (element, newData) {
          data = newData
        },

        destroy (element, data) {
          element.removeEventListener('click', onClick)
        }
      }
    },
    async chartItemClickAction (element, data) {
      let me = this

      async function onClick (event) {
        me.getModel()[me.source.chart_item_action.card.fieldId] = data.item.custom_properties[me.source.chart_item_action.card.fieldId]
        ActionExecutor.execute(me, { readonly: me.readonly, pluginName: me.source.chart_item_action.plugin_name, action: me.source.chart_item_action, event: event })
      }

      element.addEventListener('click', onClick)

      return {
        update (element, newData) {
          data = newData
        },

        destroy (element, data) {
          element.removeEventListener('click', onClick)
        }
      }
    },
    createRows (data, label = null, attr = null, startDate = null, endDate = null, linkAttribute = null, keyField = null) {
      let GSTCID = GSTC.api.GSTCID
      let result = {}
      let rows = JSON.parse(JSON.stringify(data))
      let temp = {}
      let groupingResult = {}
      let date = GSTC.api.date

      rows.forEach((item) => {
        let id

        if (keyField && typeof item[keyField] !== 'undefined') {
          id = GSTCID(`${item[keyField]}`)
        }

        // let id = keyField ? GSTCID(`${item[keyField]}`) : GSTCID(`${item.id}`)
        let row = {
          id: id,
          label: null,
          rowId: null,
          custom_properties: item,
          time: {
            start: date(item[startDate]).valueOf(),
            end: date(item[endDate]).endOf('day').valueOf()
          },
          tooltip: null,
          type: null
        }

        // row.time.start = date(row.custom_properties[startDate]).valueOf()
        // row.time.end = date(row.custom_properties[endDate]).endOf('day').valueOf()

        if (linkAttribute) {
          row.rowId = GSTCID(`${item[linkAttribute]}`)
        } else {
          row.rowId = GSTCID(`${item[keyField]}`)
        }

        try {
          row.label = JSON.parse(item[label]).name
        } catch (e) {
          row.label = item[label]
        }

        result[row.id] = row
      })

      if (this.source.use_group_by_attr !== 'none') {
        if (this.source.use_group_by_attr === 'fixed') {
          let groupingRows = this.createGroupingRows(rows, attr)
          Object.assign(temp, result, groupingRows)

          Object.values(temp).forEach((item) => {
            let parsedAttr = this.parseAttr(item.custom_properties[attr])
            let groupRow

            if (parsedAttr.hasOwnProperty('id') && item.hasOwnProperty('is_grouping_row') === false) {
              groupRow = temp[GSTCID('gr' + parsedAttr.id)]
              if (typeof groupRow !== 'undefined') {
                item.expanded = true
                item.parentId = groupRow.id
              }
            } else if (parsedAttr === 'пусто' && item.hasOwnProperty('is_grouping_row') === false) {
              item.expanded = true
              item.parentId = undefined
            } else {
              item.expanded = true
              groupRow = temp[GSTCID('gr' + parsedAttr)]
              if (typeof groupRow !== 'undefined') {
                item.parentId = item.id !== groupRow.id ? groupRow.id : undefined
              }
            }
            groupingResult[item.id] = item
          })

          result = groupingResult
        } else {
          Object.assign(temp, result)

          Object.values(temp).forEach((item) => {
            let parsedAttr = this.parseAttr(item.custom_properties[attr])
            let row = temp[GSTCID(parsedAttr.hasOwnProperty('id') ? parsedAttr.id : parsedAttr)]

            if (parsedAttr.hasOwnProperty('id') && item.hasOwnProperty('is_grouping_row') === false) {
              item.expanded = true

              if (typeof row !== 'undefined') {
                item.parentId = item.id !== row.id ? row.id : undefined
              }
            } else if (parsedAttr === 'пусто' && item.hasOwnProperty('is_grouping_row') === false) {
              item.expanded = true
              item.parentId = undefined
            } else {
              item.expanded = true

              if (typeof temp[GSTCID(parsedAttr)] !== 'undefined') {
                item.parentId = item.id !== GSTCID(parsedAttr) ? GSTCID(parsedAttr) : undefined
              }
            }
            groupingResult[item.id] = item
          })

          result = groupingResult
        }
      }

      return result
    },
    exportToPDF () {
      gstc.api.plugins.ExportPDF.download('testPDF')
    },
    parseAttr (value) {
      let parsedAttr
      try {
        parsedAttr = JSON.parse(value)
      } catch (e) {
        return typeof value !== 'undefined' ? value : 'пусто'
      }
      if ((Array.isArray(parsedAttr) && parsedAttr.length === 0) || typeof parsedAttr === 'undefined') {
        parsedAttr = 'пусто'
      } else if (Array.isArray(parsedAttr) && parsedAttr.length > 0) {
        parsedAttr = parsedAttr[0]
      }
      return parsedAttr
    },
    createChartItems (data, startDate = null, endDate = null, linkAttribute = null, progressAttribute = null, relationParentAttr = null) {
      let GSTCID = GSTC.api.GSTCID
      let date = GSTC.api.date
      let copyData = JSON.parse(JSON.stringify(data))
      let background
      let styleResult = {}

      if (this.source.row_keys.style) {
        let style = this.source.row_keys.style.match(/([^\s:;]+)/g) || []
        while (style.length) {
          styleResult[style.shift()] = style.shift() || ''
        }
      }

      Object.values(copyData).forEach((item) => {
        let itemStyle = JSON.parse(JSON.stringify(styleResult))
        background = item.custom_properties[this.source.chart_item_background_color]
        item.time.start = date(item.custom_properties[startDate]).valueOf()
        item.time.end = date(item.custom_properties[endDate]).endOf('day').valueOf()

        item.overlap = this.source.chart_item_overlap
        item.type = this.source.chart_item_type

        if (linkAttribute) {
          item.rowId = GSTCID(`${item.custom_properties[linkAttribute]}`)
        }

        if (progressAttribute) {
          item.progress = item.custom_properties[progressAttribute]
        }

        if (relationParentAttr) {
          if (item.custom_properties[relationParentAttr]) {
            item.dependant = [GSTCID(item.custom_properties[relationParentAttr])]
          }
        }

        if (typeof background !== 'undefined' && background !== '' && !itemStyle.hasOwnProperty('background')) {
          item.fill = background
        }
        item.style = itemStyle
      })

      return copyData
    },
    async getRowData (entityId, sourceType, filters) {
      let result
      let response
      let buildFilters = this.getFilters(filters)

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = response.data
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = response.data.data
          break
      }

      return result
    },
    getFilters (filters) {
      let model = typeof this.getModel() === 'object' ? this.getModel() : {}
      if (typeof filters !== 'undefined' && filters.length > 0) {
        const builder = new FilterBuilder(
          filters,
          model,
          this.$store,
          EComponentTypes.ganttNew
        )

        return {
          where: {
            and: builder.buildAsApiQl()
          }
        }
      }
      return {}
    },
    getEntityId (entityType, data) {
      let entityId
      switch (entityType) {
        case 'query':
          entityId = data.entityId
          break
        case 'registry':
          entityId = data.entityId
          break
      }
      return entityId
    },
    createGroupingRows (data, groupingAttr) {
      let GSTCID = GSTC.api.GSTCID
      let uniqueValues = [...new Set(data.map(item => item[groupingAttr]))].map(item => this.parseAttr(item))
      let result = {}

      uniqueValues.forEach(value => {
        let properties = {}
        properties[groupingAttr] = JSON.stringify([value])
        let row = {
          id: null,
          label: null,
          rowId: null,
          parentId: undefined,
          expanded: true,
          custom_properties: properties,
          time: { start: 0, end: 0 },
          tooltip: null,
          is_grouping_row: true,
          style: { 'font-weight': 'bold' }
        }

        if (typeof value === 'object') {
          row.id = GSTCID('gr' + value.id)
          row.rowId = GSTCID('gr' + value.id)
          row.label = value.name
        } else {
          row.id = GSTCID('gr' + value)
          row.rowId = GSTCID('gr' + value)
          row.label = value
        }

        result[row.id] = row
      })

      return result
    },
    itemMovementOptions () {
      let me = this
      return {
        enabled: me.source.chart_items_movement !== 'forbid',
        dependant: true,
        events: {
          onEnd ({ items }) {
            return items.before.map((beforeMovementItem, index) => {
              const afterMovementItem = items.after[index]
              const myItem = GSTC.api.merge({}, afterMovementItem)
              me.saveRecordAfterMovement(afterMovementItem, me)
              return myItem
            })
          }
        }
      }
    },
    saveRecordAfterMovement (row, me) {
      let data = {}
      let formData = new FormData()
      let objectId = row.custom_properties['object_id']
      let recordId = row.custom_properties['id']

      data[me.source.row_keys.start_date] = GSTC.api.date(row.time.start).format('YYYY-MM-DD HH:MM:ss')
      data[me.source.row_keys.end_date] = GSTC.api.date(row.time.end).format('YYYY-MM-DD HH:MM:ss')
      data['id'] = recordId

      for (let key in data) {
        formData.append(key, JSON.stringify(data[key]))
      }

      this.$http.put(`${this.$config.api}/registryservice/registry/${objectId}/records/${recordId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          hideNotification: true
        })

      if (me.source.chart_items_movement === 'multiple' && row.hasOwnProperty('dependant')) {
        me.saveRecordAfterMovement(gstc.api.getAllItems()[row.dependant[0]], me)
      }
    },
    onFullScreenChange (event) {
      this.$set(this.source, 'is_full_screen', !this.source.is_full_screen)
      this.getContainersStore().refs.map(item => {
        item.element.$el.style.zIndex = 1
      })
      if (this.getInterfaceEditorVersion() === 2) {
        if (this.source.is_full_screen) {
          this.$el.style.zIndex = 9999
          this.changeZIndex(this.$parent)
          this.$set(this.source.current_container, 'top', 0)
          this.$set(this.source.current_container, 'left', 0)
          this.$set(this.source.current_container, 'width', 100)
          this.$set(this.source.current_container, 'height', 500)
        } else {
          this.source.containers.forEach(container => {
            this.getContainersStore().refs.find(item => item.guid === container.guid).element.$el.style.zIndex = container.zIndex
          })
          this.$set(this.source.current_container, 'top', this.source.default_container.top)
          this.$set(this.source.current_container, 'left', this.source.default_container.left)
          this.$set(this.source.current_container, 'width', this.source.default_container.width)
          this.$set(this.source.current_container, 'height', this.source.default_container.height)
        }
      }
    },
    changeZIndex (component) {
      component.$el.style.zIndex = 9999
      if (component.$el.className === 'block') {
        this.changeZIndex(component.$parent)
      }
    }
  }
}
</script>

<style scoped>
.gstc {
  margin: 0;
  padding: 0;
}
.toolbox {
  padding: 10px;
  background: #F1F7FF;
  position:relative;
}
.date-select-gstc {
  width: 250px;
  margin-right: 10px;
}
.date-select-gstc /deep/ .el-input--suffix .el-input__inner {
  background: inherit;
  border: none;
}
.pdf-download {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #208BE9;
  border: none;
  background: inherit;
  position: absolute;
  right: 10px;
}
.button-zoom {
  border: none;
  background: inherit;
  margin-right: -30px;
  font-size: 1.5em;
}
.items-movement-select-gstc {
  width: 250px;
  margin-left: 20px;
}

.items-movement-select-gstc /deep/ .el-input--suffix .el-input__inner {
  background: inherit;
  border: none;
}
</style>
